.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeDown {
  animation: fadeDown 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 2s ease-in;
}

@keyframes subtleFlipUp {
  0% {
    opacity: 0;
    transform: perspective(600px) rotateX(10deg); /* Reduced angle for subtlety */
  }
  100% {
    opacity: 1;
    transform: perspective(600px) rotateX(0deg);
  }
}

.animate-flipUp {
  animation: subtleFlipUp 0.8s ease-out; /* Shorter duration for a subtle effect */
  backface-visibility: hidden;
}

@keyframes rotateLeft {
  from {
    opacity: 0;
    transform: rotate(-10deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.animate-rotateLeft {
  animation: rotateLeft 0.8s ease-out;
}

@keyframes rotateRight {
  from {
    opacity: 0;
    transform: rotate(10deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.animate-rotateRight {
  animation: rotateRight 0.8s ease-out;
}

@keyframes flipOutward {
  0% {
    transform: rotateY(0deg);
    transform-origin: left center;
  }
  100% {
    transform: rotateY(-180deg);
    transform-origin: left center;
  }
}

.flip-outward {
  animation: flipOutward 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease forwards;
}

.logo-card {
  @apply flex justify-center items-center m-2 border border-gray-200 rounded-lg overflow-hidden;
  width: 150px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.logo {
  max-width: 80%; /* Adjust logo size within card */
  max-height: 80%;
  object-fit: contain; /* Keeps logo aspect ratio */
}
